.dartmouth__selectGroupInput {
    display: flex;
    flex-direction: row;
    gap: 0.1em;
    background-color: #fefefe;
    border-radius: 0.5em;
    overflow: hidden;
    border: 0.1em solid #222;
    margin-bottom: 0.2em;
    font-size: 0.7em;
}

.dartmouth__selectGroup {
    display: flex;
    flex-direction: column;
    width: max-content;
    align-items: center;
    animation: fadeIn 500ms ease;
    user-select: none;
}

.dartmouth__rangeSliderText,
.dartmouth__rangeSliderSelectItem,
.dartmouth__rangeSliderSelectItemSelected {
    background-color: #fefefe;
    color: #444;
    padding: 0.3em 0.9em;
    font-size: 1.5em;
    font-weight: 200;
    cursor: pointer;
    text-transform: uppercase;
}

.dartmouth__rangeSliderSelectItem:hover {
    color: #000;
}

.dartmouth__rangeSliderSelectItemSelected {
    color: #fefefe;
    background-color: #444;
    cursor: default;
}
.dartmouth__rangeSliderText{
    border: none;
    font-weight: 200;
    width: fit-content;
}

