.taskItem {
    display: grid;
    grid-template-columns: 2rem 12rem 13rem 1fr 8rem 6rem auto;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem;
    border: 0.0625rem solid #ccc;
    background-color: #eee;
    border-radius: 0.25rem;
}

.taskItem > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.taskItem button {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
}

.taskItem .actions {
    display: flex;
    gap: 0.5rem;
}

.taskItem .actionSpacer {
    width: 2.2rem;
}

.taskItem button:hover {
    background-color: #0056b3;
}

.taskDetails {
    padding: 1rem;
    border-left: 0.25rem solid #007bff;
    background-color: #f9f9f9;
    border-radius: 0.25rem;
}

.taskDetails div {
    margin-bottom: 0.5rem;
}

.taskDetails .task-data > ul {
    margin: 0.5rem 0 0.5rem 1rem;
}

.taskItem ul {
    list-style-type: none;
    padding-left: 0;
}

.taskItem li {
    margin-bottom: 0.25rem;
    font-size: 0.875rem;
}
