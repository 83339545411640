.appLayout {
    display: flex;
    height: 100vh;
height: 100dvh;
    overflow: hidden;
    background: #efefef;
    box-shadow: 0 0 0 0 black inset;
    transition: box-shadow 550ms ease, filter 550ms ease;
    filter: grayscale(0);
}
body:not(:hover) .appLayout {
    box-shadow: 0 0 50px -10px black inset;
    filter: grayscale(1);
    transition-delay: 1s;
}

