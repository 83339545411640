.toolPage {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    gap: 0.5em;
    background-color: #fff;
    position: relative;
    width: 42em;
    overflow: visible;

    a {
        color: #336;
    }
}

.sectionHeader{
    margin: 0;
    padding: 0;
    font-weight: 200;
    font-size: 1.25em;
    text-transform: uppercase;
    border-bottom: 1px solid;
}

.jobListPager {
    display: flex;
    flex-direction: row;
    gap: 0.5em;
    justify-content: end;
    margin-top: 0.25em;

    .jobListPagerButton {
        padding: 0;
        cursor: pointer;
        font-family: sans-serif;
        user-select: none;
    }
    .jobListPagerButton + .jobListPagerButton::before  {
        content: '';
        display: inline-block;
        background-color: black;
        width: 0.01em;
        height: 1em;
        margin-right: 0.5em;
        margin-bottom: -0.2em;
        pointer-events: none;
    }

    .jobListPagerButton:hover {
        text-decoration: underline;
    }
}

.toolPageHeader {
    filter: invert(1);
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: sticky;
    top: 0;

    >h2 {
        font-weight: 200;
        font-size: 2em;
        text-transform: uppercase;
        color: #101010;

        margin: 0;
        padding: 0;

        span {
            font-weight: 500;
        }
    }
}

.toolPageHeader,
.toolPageBody {
    padding: 1em 1.5em;
}

.errorGenerating {
    margin-bottom: 1em;
    text-decoration: underline;
    text-decoration-color: red;
}

.builderLabel {
    font-weight: 200;
    text-transform: uppercase;
}

.toolPageBody {
    display: flex;
    flex-direction: column;
    gap: 1em;

    table {
        width: -webkit-fill-available;
        margin-left: -0.2em;
        margin-right: -0.2em;

        th {
            border-bottom: 0.05em solid #000;
            text-align: left;
            font-weight: 200;
        }

        th:not(:last-child) {
            padding-right: 0.5em;
        }

        th:last-child,
        td:last-child {
            text-align: right;
        }

        td:not(:last-child) {
            padding-right: 0.5em;
        }
    }
}

.toolPageBodySection,
.toolPageBodySectionEmpty {
    display: flex;
    flex-direction: column;
    gap: 0.2em;
}

.toolPageBody>*:last-child {
    padding-bottom: 1em;
}

.toolPageBodySectionEmpty {
    order: 10;
}

.toolPage .toolPageHeaderButtons {
    display: flex;
    flex-direction: row;
    gap: 0.5em;

    button {
        font-weight: 100;
        font-family: monospace;
        text-transform: uppercase;
        border: 0.01em solid #666;
        padding: 0.3em 1em;
        cursor: pointer;
    }

    button:hover {
        filter: invert(1);
    }
}

button.buttonGenerate {
    display: block;
    font-size: 2em;
    font-weight: 200;
    font-family: sans-serif;
    text-transform: uppercase;
    padding: 0.3em 0.5em;
    cursor: pointer;
    border: none;
    position: absolute;
    bottom: 0.5em;
    left: 0.5em;
    right: 0.5em;
    filter: invert(1);
}

button.buttonGenerate:hover {
    border: 0.01em solid #666;
    filter: invert(0);
}

.jobBuilderRow {
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.toolPageBody>div>.jobBuilderRow {
    padding-bottom: 5em;
}

.searchFilters {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.5em;
    position: sticky;
    top: 0;
    background: linear-gradient(180deg, #fff, #fff, transparent);
    padding-top: 1em;
    padding-bottom: 1em;
}

.dataBrowserPage input:invalid {
    box-shadow: 0 0 10px -1px rgb(181, 1, 1);
}

.dataBrowserPage h2 {
    font-family: monospace;
}

.dataBrowserPage table th {
    text-align: left;
    vertical-align: top;
    font-weight: 300;
    text-transform: uppercase;
    text-overflow: ellipsis;
    overflow: hidden;
    text-wrap: nowrap;
    max-width: 4em;
}

.dataBrowserPage table td {
    text-align: left;
    font-weight: 200;
    vertical-align: top;
}

.dataFooter {
    display: flex;
    flex-direction: row;
    gap: 0.5em;
    overflow: hidden;
    position: sticky;
    bottom: 1em;
    background-color: rgba(255, 255, 255, 0.8);
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-right: 0.5em;
    width: fit-content;
}

.dataFooterPageSelector,
.dataFooterPageSelectorActive {
    font-size: 0.8em;
    width: 2em;
    height: 2em;
    text-align: center;
    vertical-align: center;
    line-height: 2em;
    background-color: #fff;
    border: 0.01em solid black;
    transition: filter 1s ease;
    cursor: pointer;
}

.dataFooterPageSelector:hover,
.dataFooterPageSelectorActive:hover {
    transition: filter 250ms ease;
    filter: invert(1);
}

.dataFooterPageSelector {
    font-weight: 200;
}

.dataFooterPageSelectorActive {
    font-weight: 600;
}

.dataRecordRow {
    font-size: 0.9em;
}

.dataRecordRow--format__date-time {
    white-space: nowrap;
}

.dataRecordRow--format__uuid {
    overflow: hidden;
    max-width: 10em;
    text-overflow: ellipsis;
}

.dataRecordRow--type__array,
.dataRecordRow--type__enum {
    text-decoration: underline;
    color: #006;
}
.jobListCellAction,
.jobListCellUser,
.jobListCellBatchId,
.jobListCellCategory,
.jobListCellReference {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.jobListCellBatchId {
    font-size: 0.9em;
    width: 19em;
    vertical-align: center;
}

.jobListCellCategory {
    max-width: 10em;
}

.jobListCellReference {
    max-width: 4em;
}

.jobListCellCount {
    text-align: center;
}