.dataBrowserPage {
    display: flex;
    flex-direction: column;
    padding: 1em;
    gap: 0em;
    /* max-width: 900px; */
    margin: auto;
}

.searchFilters {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.5em;
    position: sticky;
    top: 0;
    background: linear-gradient(180deg, #fff,#fff, transparent);
    padding-top: 1em;
    padding-bottom: 1em;
}

.dataBrowserPage input:invalid {
    box-shadow: 0 0 10px -1px rgb(181, 1, 1);
}
.dataBrowserPage h2 {
    font-family: monospace;
}
.dataBrowserPage table th {
    text-align: left;
    vertical-align: top;
    font-weight: 300;
    text-transform: uppercase;
    text-overflow: ellipsis;
    overflow: hidden;
    text-wrap:nowrap;
    max-width: 4em;
}
.dataBrowserPage table td {
    text-align: left;
    font-weight: 200;
    vertical-align: top;
}

.dataFooter{
    display: flex;
    flex-direction: row;
    gap: 0.5em;
    overflow: hidden;
    position: sticky;
    bottom: 1em;
    background-color: rgba(255,255,255,0.8);
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-right: 0.5em;
    width: fit-content;
}
.dataFooterPageSelector,
.dataFooterPageSelectorActive {
    font-size: 0.8em;
    width: 2em;
    height: 2em;
    text-align: center;
    vertical-align: center;
    line-height: 2em;
    background-color: #fff;
    border: 1px solid black;
    transition: filter 1s ease;
    cursor: pointer;
}
.dataFooterPageSelector:hover,
.dataFooterPageSelectorActive:hover {
    transition: filter 250ms ease;
    filter: invert(1);
}
.dataFooterPageSelector{
    font-weight: 200;    
}
.dataFooterPageSelectorActive{
    font-weight: 600;
}

.dataRecordRow{
    font-size: 0.9em;
}
.dataRecordRow--format__date-time{
    white-space: nowrap;
}
.dataRecordRow--format__uuid{
    overflow: hidden;
    max-width: 10em;
    text-overflow: ellipsis;
}
.dataRecordRow--type__array,
.dataRecordRow--type__enum{
    text-decoration: underline;
    color: #006;
}