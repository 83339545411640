
.modalContainer{
    position: absolute;
    inset: 0;

    background-color: #000b;
    height: 100vh;
height: 100dvh;
    z-index: 1000000;
    animation: fadeIn 350ms;
    overflow-y: auto;
}
.modalContainerWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: max-content;
    width: auto;
    margin: auto;
    margin-top: 3em;
}
.modalContainerBehind {
    z-index: 10;
}
.modalContent{
    display: flex;
    flex-direction: column;
    padding: 1em 1.5em;
    background-color: #cccf;
    height: max-content;
    margin: auto;
    margin-top: 3em;
    min-width: 20em;
    width: fit-content;
}
.modalContent button {
    cursor: pointer;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
