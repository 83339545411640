.storageLink {
    background-color: #efefef;
    padding: 2em;
    color: #333;
    text-align: left;
    aspect-ratio: 1.5;
    width: 8em;
    filter: invert(0);
    transition: filter 650ms ease;
}

.storageLink:hover {
    transition: filter 0ms ease;
    filter: invert(1);
}

.pageWrapper {
    position: absolute;
    inset: 0;
    background: linear-gradient(45deg, #bbfb6f, #fbe66f, #b8fec4);
    animation: backgroundRotate 10s ease;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}

@keyframes backgroundRotate {
    0% {
        filter: saturate(0.7) hue-rotate(0deg);
    }
    100% {
        filter: saturate(0.7) hue-rotate(45deg);
    }
}

.storageLinkContainer {
    display: flex;
    flex-direction: row;
    gap: 1em;
    flex-flow: wrap;
    width: 38em;
}

.storageLinkContainer>a {
    text-decoration: none;
}

.storageLayout {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    height: 100%;
    font-size: 1.25em;
    font-weight: 300;

    width: fit-content;
    margin: auto;
}