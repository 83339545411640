.threatItemContainerSelected,
.threatItemContainer {
    position: absolute;
    bottom: 0;
    height: 5em;
    display: flex;
    gap: 0.65em;
    flex-direction: row;
    z-index: 1000;
    left: 0;
    padding: 0.5em 3.5em;
    background: transparent;
    right: 0;
    transition: transform 550ms ease, opacity 650ms ease;
    opacity: 0.5;
    background: linear-gradient(to top, black 0%, rgba(0, 0, 0, .738) 19%, rgba(0, 0, 0, .541) 34%, rgba(0, 0, 0, .382) 47%, rgba(0, 0, 0, .278) 56.5%, rgba(0, 0, 0, .194) 65%, rgba(0, 0, 0, .126) 73%, rgba(0, 0, 0, .075) 80.2%, rgba(0, 0, 0, .042) 86.1%, rgba(0, 0, 0, .021) 91%, rgba(0, 0, 0, .008) 95.2%, rgba(0, 0, 0, .002) 98.2%, transparent 100%);
    transform: translateY(5.5em);
    background-position-y: 1em;
    background-repeat: no-repeat;
    background-size: 110% 110%;
    justify-content: center;
}

.threatItemContainerSelected:hover,
.threatItemContainer:hover {
    opacity: 1;

    .threatItemContainerHandle {
        opacity: 0;
        animation: none;
    }
}

.threatItemContainerHandle {
    position: absolute;
    top: -0.5em;
    content: '';
    background: #444;
    width: 1.5em;
    height: 0.5em;
    border-radius: 0.2em;
    animation-direction: alternate;
    animation: pulse 2s ease infinite;
}

@keyframes pulse {
    0% {
        opacity: 0.5;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0.5;
    }
}

.threatItemContainerSelected::after,
.threatItemContainer::after {
    display: block;
    content: '';
    position: absolute;
    left: 0;
    bottom: 6em;
    right: 0;
    height: 2em;
}

.threatItemContainerSelected {
    transform: translateY(6em);
}

body:not(:hover) .threatItemContainerSelected {
    opacity: 1;

    .threatItemContainerHandle {
        opacity: 0;
        animation: none;
    }
}

body:not(:hover) .threatItemContainer {
    transition-delay: 1s;
    transform: translateY(0);
    opacity: 1;

    .threatItemContainerHandle {
        opacity: 0;
        animation: none;
    }
}
.threatItemContainerFixed,
.threatItemContainer:hover {
    opacity: 1;
    transform: translateY(0);

    svg {
        display: block;
    }
}

/* .threatItemContainer {
    left: 0.5em;
}
body:not(:hover) .threatItemContainer {
    transition-delay: 1s;
    left: 3.5em;
} */
.threatItemContainer.threatItemContainerHidden {
    left: 0.5em;
}

.threatItemContainerDisabled {
    button {
        display: none;
    }
}

.threatItemContainerSelected {
    svg {
        display: none;
    }

    button {
        display: none;
    }
}

.threatItemContainer:has(.threatSelected)>.threatItemImage:not(.threatSelected) {

    img,
    svg {
        filter: brightness(0.5);
    }

    button {
        display: none;
    }
}

.threatItemContainerSelected,
.threatItemContainer {
    z-index: 15;

    .threatItemImage:hover button {
        opacity: 1;
    }

    .threatItemImage {
        &.threatSelected img {
            box-shadow: 0 0 0.2em 0 rgb(0, 0, 137);
        }

        z-index: 10;
        position: relative;
        display: flex;
        flex-direction: column;
        width: 5em;

        img,
        .threatItemImagePlaceholder {
            height: 100%;
            overflow: hidden;
            filter: brightness(1);
            transition: filter 350ms ease;
        }

        &.threatItemClutter img {
            box-shadow: 0 0 0.3em 0 blue;
        }
        
        img {
            aspect-ratio: auto;
            border-radius: 0.4em;
            cursor: zoom-in;
            border: 0.1em solid rgba(0, 0, 0, 0.7);
            box-shadow: 0 0 0.3em 0 rgba(0, 0, 0, 0.4);
            object-fit: cover;
            text-indent: -1000px;
            align-content: center;
        }


        .threatItemImagePlaceholder {
            background-color: #333;
            color: #eee;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 0.05em solid rgba(0, 0, 0, 0.7);
            border-radius: 0.2em;
            box-shadow: 0 0 0.3em 0 rgba(0, 0, 0, 0.4);
            font-size: 2em;
        }

        button {
            text-transform: uppercase;
            font-weight: 200;
            cursor: pointer;
            position: absolute;
            bottom: 0.3em;
            left: 0.3em;
            right: 0.3em;
            opacity: 0;
            transition: opacity 250ms ease;
            padding: 0.4em 0;
            font-size: 0.7em;
            background-color: #222;
            color: #fefefe;
            border: none;
        }

        button:hover {
            filter: invert(1);
            border: 1px solid #fefefe;
        }

        &>svg {
            position: absolute;
            top: 0.25em;
            right: 0.25em;
            width: 1em;
            height: 1em;
            z-index: 10;
        }

        &>svg.threatStatusDraft {}

        &>svg.threatStatusPending {}

        &>svg.threatStatusComplete {}

        &>svg.threatStatusRejected {}
    }

    img {
        height: 100%;
    }

    .threatItemCategoryLabel {
        position: absolute;
        width: auto;
        margin: auto;
        height: auto;
        opacity: 0;
        bottom: 1.5em;
        font-size: 2em;
        color: #666;
        background: white;
        padding: 0.25em 0.8em;
        text-transform: uppercase;
        font-weight: 200;
        border-radius: 0.25em;
        box-shadow: 0 0 5px inset;
        border: 1px solid #666;
        pointer-events: none;
        
        div {}

        div:last-of-type {
            font-size: 0.7em;
        }
    }

    .threatItemImageSelected {
        height: 100vh;
height: 100dvh;
        box-sizing: border-box;
        position: fixed;
        inset: 0;
        top: -100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2em;
        transition: background-color 550ms ease;
        transition-delay: 350ms;
        background-color: rgba(0, 0, 0, 0.5);

        img {
            border-radius: 0.5em;
        }

        .threatItemCategoryLabel {
            opacity: 1;
            user-select: none;
            pointer-events: all;
        }

        .threatItemCategoryLabel:hover {
            opacity: 0;
            transition: opacity 350ms ease;
        }
    }
}