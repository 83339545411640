.identContainer {
    display: block;
    font-size: 2em;
    overflow: visible;
    height: 1em;
    text-align: center;
    box-sizing: border-box;
    font-weight: 700;
    user-select: none;
    white-space: nowrap;

    background: linear-gradient(311deg, #000, #bbdf7d, #130b99);
    background-size: 800% 800%;
    border: none;
    animation: gradientRotation 8s ease infinite;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.identContainerVertical {
    position: absolute;
    bottom: 1.5em;
    left: 1.5em;
    font-size: 2em;
    height: 1.5em;
    box-sizing: border-box;
    font-weight: 700;
    user-select: none;
    white-space: nowrap;
    background: linear-gradient(311deg, #000, #bbdf7d, #130b99);
    background-size: 800% 800%;
    border: none;
    animation: gradientRotation 8s ease infinite;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    transform: rotate(-90deg);
    width: auto;
    transform-origin: 0 1.45em;
    line-height: 1.5em;
    text-transform: uppercase;
}

@keyframes gradientRotation {
    0% {
        background-position: 77% 0%
    }

    50% {
        background-position: 24% 100%
    }

    100% {
        background-position: 77% 0%
    }
}


.altText {
    font-weight: 300;
}