.reportDemoControls,
.reportDemoControlsVisible {
    position: absolute;
    left: 0;
    right: 0;
    top: -3em;
    z-index: 14;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0;
    font-size: 2em;
    color: #555;
    opacity: 1;
    transition: 350ms opacity ease;
    transition: top 500ms ease;
    pointer-events: none;
}

.reportDemoControlsVisible {
    pointer-events: all;
    top: 0;
}

.reportFileControls,
.reportFileControlsVisible {
    position: absolute;
    left: 0;
    right: 0;
    top: -3em;
    z-index: 14;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0;
    font-size: 1.5em;
    color: #333;
    opacity: 1;
    transition: 350ms opacity ease;
    transition: top 500ms ease;
    pointer-events: none;
}

.reportFileControlsVisible {
    pointer-events: all;
    top: 0;
}

/* 
.reportFileControls:hover {
    opacity: 1;
} */

.reportFileButtonWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    justify-items: center;
    gap: 0.75em;
    padding: 0.2em 1em;
}

.reportFileLabel {
    padding: 0.3em 0;
    user-select: none;
    font-weight: 200;
    cursor: help;
}

.reportFileLabelPath {
    text-decoration: underline #ccc;
    text-overflow: ellipsis;
    max-width: 50vw;
    overflow: hidden;
    text-align: left;
    width: min-content;
    white-space: nowrap;
    user-select: text;
    display: flex;
    gap: 0.5em;

    svg {
        scale: 0.8;
        cursor: pointer;
    }
}

.reportFileMode,
.reportFileModeAdmin {
    text-transform: uppercase;
    font-size: 0.5em;
    color: #000;
}

.reportFileModeAdmin {
    cursor: pointer;
    color: #f00;
}

.reportFileLabelDetail {
    font-size: 0.7em;
    position: relative;
    width: 100%;
    border-bottom: 1px solid #ddd;
}

.reportFileLabelDetail span {
    top: 1.2em;
    left: 0;
    position: absolute;
    font-weight: 400;
    color: #b00;
}

.reportFileButton,
.reportFileButtonDisabled {
    cursor: pointer;
    font-size: 1.5em;
    color: #999;
    transform: scaleY(0.7) translateY(-0.04em);
    transform-origin: center center;
}

.reportFileButtonDisabled {
    opacity: 0.3;
    pointer-events: none;
}

.reportFileButton:hover {
    color: #000;
}

@keyframes slideInFromTop {
    0% {
        top: -4em;
    }

    100% {
        top: 0em;
    }
}