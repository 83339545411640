.formContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1.5rem;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: 1rem auto;
}

.formGroup {
    display: flex;
    flex-direction: column;
}

.label {
    font-size: 0.9rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
}

.mandatory {
    color: red;
    font-size: 0.8rem;
    margin-left: 0.25rem;
}

.input {
    padding: 0.5rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    transition: border 0.3s;
}

.input:focus {
    border-color: #0070f3;
    box-shadow: 0 0 4px rgba(0, 112, 243, 0.2);
}

.select {
    padding: 0.5rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
}

.radioGroup,
.checkboxGroup {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 0.5rem;
}

.radioItem,
.checkboxItem {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.radioButton,
.checkbox {
    cursor: pointer;
}

.radioLabel,
.checkboxLabel {
    font-size: 1rem;
    cursor: pointer;
}

.formActions {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-top: 1rem;
}

.submitButton {
    background-color: #007bff;
    border: none;
    color: #fff;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
}

.submitButton:hover {
    background-color: #0056b3;
}

.cancelButton {
    background-color: #f1f1f1;
    border: 1px solid #ccc;
    color: #333;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
}

.cancelButton:hover {
    background-color: #e4e4e4;
}

.closeButton {
    background:none;
    border:none;
    cursor:pointer;
    padding: 0;
    position: absolute;
    top: 0.25rem;
    right: 0.25rem;
}

.successMessage,
.errorMessage {
    position: relative;
    margin-top: 1rem;
    padding: 1rem 2rem 1rem 1rem;
    border-radius: 4px;
    font-size: 1rem;
}

.successMessage {
    background-color: #d4edda;
    color: #155724;
    border: 1px solid #c3e6cb;
}

.errorMessage {
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
}
