.statusIcon {
    font-size: 1.5rem;
    margin-right: 0.5rem;
    vertical-align: middle;
}

.pending {
    color: orange;
}

.queued {
    color: blue;
}

.inprogress {
    color: teal;
}

.animated {
    animation: rotate 1s linear infinite;
}

.completed {
    color: green;
}

.failed {
    color: red;
}

.cancelled {
    color: gray;
}

.unknown {
    color: black;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
