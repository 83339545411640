/* @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap'); */
body {
  margin: 0;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior-y: none;
  width: 100vw;
  height: 100vh;
height: 100dvh;
}

body div.dartmouth__toolbarContainer{
  max-height: 100vh;
  max-height: 100dvh;
}

.__hideOnBlur {
  transition: opacity 350ms ease;  
}
body:not(:hover) .__hideOnBlur {
  opacity: 0;
  transition-delay: 1s;
}

:root{
  --primary-bg: #0037ff;
  --primary-fg: #222;
  --invert-fg: #fff;
  --alt-fg: #00DDEE;

  --toolbar-background: var(--primary-bg);
  --toolbar-button-fill: var(--primary-fg);
  --toolbar-button-fill-active: var(--invert-fg);
  --toolbar-button-fill-hover: var(--alt-fg);
  --toolbar-spacer-fill: var(--primary-fg);
}
