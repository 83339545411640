.filters {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    align-items: flex-start;
    justify-content: center;
}

.filterGroup {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.25rem;
}

.filterGroup label {
    font-size: 0.875rem;
    font-weight: bold;
}

.textInput,
.dateInput {
    width: 100%;
    height: 38px;
    font-size: 0.875rem;
    padding: 0.5rem;
    line-height: 1.5;
    border: 1px solid #ccc;
    border-radius: 0.25rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
}

.textInput {
    max-width: 10rem;
}

.dateInput {
    max-width: 12rem;
}

.reactSelectContainer {

}

.reactSelectControl
{
    min-width: 15rem;
    height: auto;
    font-size: 0.875rem;
    border: 1px solid #ccc;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
}
