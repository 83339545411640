.filterPaginationContainer {
    position: sticky;
    top: 0;
    left: 1rem;
    right: 1rem;
    background-color: #fff;
    z-index: 1000;
    padding: 1rem;
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
}

.taskList {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    margin-top: 0.8rem;
}

.addTaskButton {
    position: absolute;
    top: 4.5rem;
    right: 0.5rem;
    border: none;
    background-color: #058905;
    color: #fff;
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}
