.progressBarContainer {
    position: absolute;
    inset: 0;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    transition: opacity 1s ease;
    font-weight: 200;
    width: 20em;
    margin: auto;
    gap: 0.5em;
}

.progressBarContainer label {
    padding-top: 0.25em;
    text-transform: none;
}

.progressBarContainerVisible {
    opacity: 1;
}

.progressBarElement {
    font-size: 2em;
    height: 0.5em;
    width: 100%;
    margin: 0;
    /* border-radius: 0; */
}

div.progressBarInfoLabel {

}

div.progressBarStatusLabel,
div.progressBarInfoLabel {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
}

div.progressBarStatusLabel label:first-child,
div.progressBarInfoLabel label:first-child {
    align-self: flex-start;
    line-height: 1em;
}

div.progressBarStatusLabel label:first-child{
    font-weight: 400;
    color: #333;
}

div.progressBarInfoLabel label:first-child{

}

div.progressBarStatusLabel label:last-child,
div.progressBarInfoLabel label:last-child {
    align-self: flex-end;
    line-height: 1em;
}