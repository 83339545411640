.folderSelectButton,
.folderSelectButtonDisabled,
.folderSelectButtonHighlight {
    position: absolute;
    left: 1em;
    bottom: 1.2em;
    right: 1em;
    cursor: pointer;
    padding: 1em;
    font-size: 1em;
    font-weight: 200;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    transition: 1s background-color ease;
    letter-spacing: 0.1em;
    user-select: none;
}

.folderSelectButton {
    background-color: #111;
}

.folderSelectButtonDisabled {
    pointer-events: none;
    background-color: #333;
    color: #000;
}

.folderSelectButtonHighlight {
    background-color: #690202;
}

.rowsContainer {
    display: flex;
    flex-direction: column;
    gap: 0.25em;
    font-size: 1.2em;
    font-family: monospace;
    overflow-y: auto;
}

.sidebarTitle {
    font-size: 2em;
    font-weight: 200;
    margin-bottom: 0.5em;
    user-select: none;
    display: flex;
    flex-direction: row;
    text-transform: uppercase;
}

.rowHeader{
    display: flex;
    flex-direction: row;
    user-select: none;
    gap: 1em;
    border-bottom: 1px solid #333;
    margin-bottom: 0.1em;
}

.rowHeaderLabel {
    flex-grow: 2;
}
.rowHeaderLabel,
.rowHeaderOther,
.rowHeaderCategory{
    font-weight: 200;
}

.rowItem,
.rowItemSelected,
.rowItemInactive {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    user-select: none;
    gap: 1em;
    color: #333;
}

.rowItemSelected{
    font-weight: 500;
    color: #000;
    cursor: default;
}

.rowItemInactive {
    font-weight: 200;
    cursor: default;
    color: #003;
}

.rowItemId {
    font-size: 0.8em;
}

.rowItemLabel {
    flex-grow: 2;
}

.fileTreeContainer {
    flex: 1;
    overflow: auto;
}

.userProfileContainer{
    gap: 0.5em;
    display: flex;
    flex-direction: column;
}
.userProfileRow b {
    text-transform: uppercase;
    font-weight: 200;
}

.sidebarWrapper,
.sidebarWrapperVisible {
    min-width: 20em;
    border-right: 1px solid #ccc;
    position: absolute;
    top: 0;
    bottom: 0;
    transition: 450ms left ease-out;
    background-color: #aaa;
    z-index: 10000;
    opacity: 0.8;
    display: flex;
    padding: 1.2em 1em;
    flex-direction: column;
    left: -30em;
}

.sidebarWrapperVisible {
    left: 3em;
}


input.searchTermInput {
    padding: 0;
    margin: 0;
    font-size: 1em;
    border: none;
    background: transparent;
    outline: none;
    font-weight: 200;
    width: 9em;
}

.sidebarHeaderElement{
    flex-grow: 2;
}

.reportHeaderSearchButton {
    cursor: pointer;
}

.reportHeaderSearchButton:hover {
    fill: #00b;    
}