.treeViewContainer {
    display: flex;
    flex-direction: column;
    flex-grow: 2;
    font-size: 1.2em;
    font-weight: 300;
    font-family: monospace;
    margin-bottom: 4em;
}

.treeViewItemContainerHasData {
    .treeViewItemIcon {
        color: rgb(151, 0, 118);
    }
}

.treeViewItemContainerSelected,
.treeViewItemContainer,
.treeViewSearchContainer {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    transition: 250ms color ease;
}

.treeViewPathContainer {
    margin-bottom: 0.5em;
    font-size: 0.8em;
    span {
        color: #333;
    }
}

.treeViewItemContainerSelected .treeViewItemLabel {
    text-decoration: underline;
}

.treeViewItemContainer:hover,
.treeViewItemContainerSelected:hover {
    color: rgb(0, 0, 151);
}

.treeViewSearchContainer {
    border: 1px dashed #222;
    border-radius: 0.15em;
    margin-bottom: 0.5em;
    position: relative;

    input {
        padding: 0.5em 1em;
        width: 100%;
        border: none;
        background-color: transparent;
        outline: none;
    }

    svg {
        position: absolute;
        right: 0.5em;
        cursor: pointer;
    }
}

.treeViewItemIcon {
    width: 1.1em;
    height: 100%;
    margin-top: 0.2em;
}

.treeViewItemNote {
    font-size: 0.6em;
    padding-top: 0.2em;
    height: 1em;
    text-decoration: none;
}

.treeViewItemIconSvg {
    font-size: 0.8em;
    width: 1em;
    height: 1em;
}

.treeViewItemLabel {
    flex-grow: 2;
}