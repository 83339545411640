
.boundingBoxClose {
    position: absolute;
    right: 4em;
    top: 1em;
    pointer-events: all;
    cursor: pointer;
    animation: fadeIn 350ms;
    animation-fill-mode: both;
}

@keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

.boundingBoxClose > * {
    font-size: 2em;
}

.boundingBoxWizardPrompt {
    position: absolute;
    left: 4em;
    bottom: 3em;
    pointer-events: none;
    animation: fadeIn 350ms;
    animation-fill-mode: both;
}

.boundingBoxWizardPromptMessage {
    width: 60vw;
    color: #666;
    font-weight: 200;
    font-size: 1.5em;
}
