.loginContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    position: absolute;
    gap: 1em;
    inset: 0;
    width: fit-content;
    margin: auto;
    animation: fadeIn 1.5s;
    animation-delay: 250ms;
    opacity: 0;
    animation-fill-mode: both;
}
.loginWrapper{

}
.loginWrapper > div.particleSphere {
    animation: fadeIn 2s;
    animation-fill-mode: both;
}

@keyframes fadeIn {
    0% {
      opacity: 0;  
    }    
    100% {
        opacity: 1;
    }
}
@keyframes squishStretch {
    0% {
        transform: scaleX(1) scaleY(1);
    }    
    30% {
        transform: scaleX(1) scaleY(0.05);
        color: rgba(0,0,0,0);
    }    
    100% {
        transform: scaleX(100) scaleY(0.05);
    }    
}

.loginPromptButton.clicked {
    pointer-events: none;
    animation: squishStretch 1s;
    animation-fill-mode: both;
    background: #000;
    transition: background-color 1s ease;
}
.loginPromptButton {
    filter: saturate(0.5);
    opacity: 0.6;
    font-size: 1.2em;
    font-weight: 200;
    padding: 0.4em 0.8em;
    cursor: pointer;
    box-shadow: 0 0 0.3em 0.05em rgba(0,0,0,0.3);
    width: 100%;
    text-transform: uppercase;

    background: linear-gradient(311deg, #112def, #eee, #f200ce);
    background-size: 600% 600%;
    border: none;
    animation: gradientRotation 8s ease infinite;
}

@keyframes gradientRotation {
    0%{background-position:77% 0%}
    50%{background-position:24% 100%}
    100%{background-position:77% 0%}
}