
.sidebarContainer {
    position: absolute;
    top: 0;
    bottom: 0;
    padding: 0.5em;
    background-color: #333;
    z-index: 20000;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    box-shadow: 0 0 0.1em black;
    background: linear-gradient(180deg, #333, #000);
    left: -3em;
    transition-delay: 3s;
    transition: left 550ms ease;
}
.sidebarContainerActive {
    left: 0;
}
body:not(:hover) .sidebarContainer {
    transition-delay: 1s;
    left: 0;
}
.sidebarContainer:hover {
    left: 0;
}
.sidebarContainer::before {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 5em;
    width: 5em;
}

.sidebarButtons {
    display: flex;
    flex-direction: column;
}

.sidebarContainerToggle {
    display: block;
    font-size: 2em;
    margin: 0.1em;
    box-sizing: border-box;
    font-weight: 200;
    user-select: none;
    cursor: pointer;
    position: relative;
}

.sidebarContainerInfo {

}

.sidebarContainerErrors {

}
.circleRedAnnotation {
    position: absolute;
    bottom: 0.05em;
    right: 0.05em;
    border-radius: 50%;
    background-color: #b00;
    width: 1.2em;
    text-align: center;
    height: 1.2em;
    font-size: 0.4em;
    color: #ddd;
    font-weight: 500;
    line-height: 1.1em;
}
.sidebarProfileToggle{
    cursor: pointer;
    filter: none;
    transition: filter 500ms ease;
}
.sidebarProfileToggle:hover {
    filter: drop-shadow(0 0 0.5em #99f)!important;
}

.sidebarProfileToggle > div {
    white-space: nowrap;
    background: linear-gradient(221deg, #555, #bbdf7d, #130b99);
    background-size: 400% 400%;
    border: none;
    animation: gradientRotation 8s ease infinite;
    -webkit-text-fill-color: transparent;
    color: transparent;
    height: 2em;
    width: 2em;
    transform-origin: 0 0;
    clip-path: url(#profileToggleClip);
    overflow: visible;
    position: relative;
    transform: translateX(0.05em);
}

.sidebarProfileToggle > div > svg > g > clipPath {
    transform: scale(2);
}

@keyframes gradientRotation {
    0% {
        background-position: 77% 0%
    }

    50% {
        background-position: 24% 100%
    }

    100% {
        background-position: 77% 0%
    }
}
.sidebarContainerToggle svg{
    width: 0.8em;
    height: 0.8em;
}
