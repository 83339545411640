.pagination {
    margin-top: 1rem;
    display: flex;
    gap: 0.625rem;
    justify-content: center;
}

.paginationInfo {
    display: flex;
    align-items: center;
    gap: 0.625rem;
}

.paginationSelect {
    margin-left: 1rem;
    padding: 0.3125rem;
    font-size: 0.875rem;
    height: 1.875rem;
}

.paginationControls {
    display: flex;
    align-items: center;
    gap: 0.3125rem;
}

.paginationButton {
    padding: 0.3125rem 0.625rem;
    font-size: 0.875rem;
    cursor: pointer;
    border: 0.0625rem solid #ccc;
    background-color: #f9f9f9;
    border-radius: 0.1875rem;
    height: 1.875rem;
}

.paginationButton:hover:not([disabled]) {
    background-color: #e0e0e0;
}

.paginationButton:disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

.paginationButtonActive {
    font-weight: bold;
    border-color: #000;
    background-color: #d0d0d0;
}
