.scanViewContent {
    z-index: 10;
    position: absolute;
    inset: 0;
}

.scanDebugInfo {
    position: absolute;
    bottom: 1em;
    right: 4em;
    z-index: 20;
    user-select: none;
    /* display: none; */
}

.scanDebugInfoContent {
    font-weight: 200;
    font-size: 1.5em;
    color: #999a;
}

.scanDebugInfoFilename {
    color: #99fa;
}


.reportFileControls,
.reportFileControlsVisible {
    position: absolute;
    left: 0;
    right: 0;
    top: -3em;
    z-index: 14;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0;
    font-size: 1.5em;
    color: #333;
    opacity: 1;
    transition: 350ms opacity ease;
    transition: top 500ms ease;
    pointer-events: none;
}

.reportFileControlsVisible {
    pointer-events: all;
    top: 0;
}

/* 
.reportFileControls:hover {
    opacity: 1;
} */

.reportFileButtonWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    justify-items: center;
    gap: 0.75em;
    padding: 0.2em 1em;
}

.reportFileLabel {
    padding: 0.3em 0;
    user-select: none;
    font-weight: 200;
    cursor: help;
}

.reportFileLabelPath {
    text-decoration: underline #ccc;
    text-overflow: ellipsis;
    max-width: 30vw;
    overflow: hidden;
    direction: rtl;
    text-align: left;
}

.reportFileLabelDetail {
    font-size: 0.7em;
    position: relative;
    width: 100%;
    border-bottom: 1px solid #ddd;
}

.reportFileLabelDetail span {
    top: 1.2em;
    left: 0;
    position: absolute;
    font-weight: 400;
    color: #b00;
}

.reportFileButton,
.reportFileButtonDisabled {
    cursor: pointer;
    font-size: 1.5em;
    color: #999;
    transform: scaleY(0.7);
    transform-origin: center center;
}

.reportFileButtonDisabled {
    opacity: 0.3;
    pointer-events: none;
}

.reportFileButton:hover {
    color: #000;
}

@keyframes slideInFromTop {
    0% {
        top: -4em;
    }

    100% {
        top: 0em;
    }
}
