.fullLayout,
.fullLayoutHidden {
    opacity: 0.7;
    display: flex;
    position: absolute;
    inset: 0;
    z-index: 14;
    flex-direction: column;
    background-color: aliceblue;
    transition: opacity 350ms ease;
}
div.fullLayoutHidden {
    pointer-events: none;
    opacity: 0;
    z-index: 13;
}

.fullLayout.fullLayoutFullscreen,
.fullLayoutHidden.fullLayoutFullscreen{
    z-index: 100;
}
.paneBody {
    flex-direction: column;
    button {
        background-color: #000;
        width: 100%;
        font-size: 0.6em;
        border: none;
        color: #fff;
        padding: 0.5em;
        border: 1px solid #fff;
        cursor: pointer;
        opacity: 0.8;
        font-family: monospace;
    }
    button:nth-child(odd) {
        filter: invert(1);
    }
    & > button:hover{
        opacity: 1;
    }
}